export const FULFILLMENT_SHEET_COLUMN_LENGTH = 22;
export const NORMAL_SHEET_COLUMN_LENGTH = 27;
export const NORMAL_KSA_SHEET_COLUMN_LENGTH = 23;

export const UploadFulfillmentTemplateAR =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20Fulfillment%20Upload%20Template%20AR.V5.0.xlsx';

export const UploadFulfillmentTemplate =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20Fulfillment%20Upload%20Template%20ENG.V5.0.xlsx';

export const UploadFulfillmentTemplateKSA_AR =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20Fulfillment%20Upload%20Template%20KSA_AR.V5.0.xlsx';

export const UploadFulfillmentTemplateKSA_EN =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20Fulfillment%20Upload%20Template%20KSA_ENG.V5.0.xlsx';

export const UploadFXFOrdersTemplate =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20Fulfillment%20Upload%20Template.V4.2.xlsx';

export const UploadFXFOrdersTemplateKSA =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20Fulfillment%20KSA%20Upload%20Template.xlsx';

export const SmartUploadOrdersTemplateAR =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20Upload%20Template%20AR%20V3.1.xlsx';

export const SmartUploadOrdersTemplate =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20Upload%20Template%20ENG%20V3.1.xlsx';

export const SmartUploadOrdersTemplateKSA_AR =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20Upload%20Template%20KSA%20AR%20V3.1.xlsx';

export const SmartUploadOrdersTemplateKSA =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20Upload%20Template%20KSA%20ENG%20V3.1.xlsx';

export const UploadOrdersTemplateKSA =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20Upload%20Template%20KSA%20V2.8.xlsx';

export const UploadOrdersTemplate =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20Upload%20Template%20V2.8.xlsx';

export const SmartUploadInternationalOrderTemplateAR =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20International%20Upload%20Template%20AR.V1.0.xlsx';

export const SmartUploadInternationalOrderTemplateEN =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20International%20Upload%20Template%20ENG.V1.0.xlsx';

export const SmartUploadInternationalOrderTemplateAR_EG =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20International%20Upload%20Template%20AR_EG.V1.0.xlsx';

export const SmartUploadInternationalOrderTemplateEN_EG =
  'https://storage.googleapis.com/bosta-fe/Sllr-App/Mass-Upload-Sheets/Bosta%20International%20Upload%20Template%20ENG_EG.V1.0.xlsx';
