import { bostaHttp } from 'http.js';

import { getCurrentUserCountryData } from 'constants/countries/countries-mapping';

const userCountry = () =>
  JSON.parse(localStorage.getItem('userInfo'))?.user?.country?._id;

export const fetchCities = (payload) => {
  const data = {
    ...payload,
    ...(!payload?.countryId && { countryId: userCountry() })
  };

  return bostaHttp.get('/cities', data);
};

export const fetchZones = (id) => {
  return bostaHttp.get(`/zones?cityId=${id}`);
};

export const getPinLocationAreas = (data) => {
  const payload = {
    countryCode: getCurrentUserCountryData().codeName,
    ...data
  };
  return bostaHttp.get(`/cities/pinLocation`, payload);
};

export const getAllAreas = (payload) => {
  const data = {
    ...(!payload?.countryId && { countryId: userCountry() }),
    ...payload
  };

  return bostaHttp.get('/cities/getAllDistricts', data);
};

export const getCountryZones = (countryId) => {
  const payload = {
    countryId: countryId || userCountry()
  };

  return bostaHttp.get(`/cities/country-zones`, payload);
};

export const getCountryTimeZone = () => {
  return bostaHttp.get(`/cities/country/time-zone/${userCountry()}`);
};

export const getGeocode = (payload) => {
  return bostaHttp.get('/utils/maps/geocode', payload);
};

export const getDistrictSuggestions = (payload) => {
  return bostaHttp.get('/deliveries/district-suggestions', payload);
};
