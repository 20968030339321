import { bostaHttp } from 'http.js';

export const getSignupPricingInfo = (payload) => {
  return bostaHttp.get(`/pricing/calculator`, payload);
};

export const uploadNationalID = () => {
  return bostaHttp.get(`/businesses/nationalIdUploadLink`);
};

export const getNationalID = () => {
  return bostaHttp.get(`/businesses/nationalIdGetLink`);
};

export const uploadTaxID = () => {
  return bostaHttp.get(`/businesses/taxIdUploadLink`);
};

export const getTaxID = () => {
  return bostaHttp.get(`/businesses/taxIdGetLink`);
};

export const getLegalDocUploadLink = (payload) => {
  return bostaHttp.get(`/businesses/getLegalDocUploadLink`, payload);
};

export const getLegalDocReadLink = (documentName) => {
  return bostaHttp.get(
    `/businesses/getlegalDocReadLink?documentName=${documentName}`
  );
};

export const getFeatureToggle = (payload) => {
  return bostaHttp.get('/utils/feature-flow', payload);
};

export const setInsurancePlan = (payload) => {
  return bostaHttp.post(`/users/insurance-plan`, payload);
};
