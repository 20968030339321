import React, { useContext, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Modal } from 'antd';

import { setInsurancePlan } from 'services/signup';
import { updateInsurancePlan } from 'services/insurance';
import { updateFeatureConfiguration } from 'services/auth';
import { ContextWrapper } from 'contexts/wrapper.context';

import InsurancePlanDetails from './components/InsurancePlanDetails/InsurancePlanDetails';
import InsurancePlanConfirmation from './components/InsurancePlanConfirmation/InsurancePlanConfirmation';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import './InsurancePlanModal.less';

const InsurancePlanModal = ({
  intl,
  history,
  plan,
  isSettings,
  handleSignupSuccess,
  getPlans,
  planDetails,
  handlePopupClose,
  close,
  ...props
}) => {
  const { setBusinessInfo, businessInfo } = useContext(ContextWrapper);

  const { isDefault, rank, _id } = plan;
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const isSignup = history.location.pathname.includes('signup');
  const isHomepage = history.location.pathname.includes('overview');

  const handleIsInsurancePopUpViewed = async () => {
    try {
      const payload = {
        showSelectInsurancePlanPopUp: false
      };

      await updateFeatureConfiguration(payload);

      setBusinessInfo({
        ...businessInfo,
        featuresConfigurations: {
          ...businessInfo.featuresConfigurations,
          showSelectInsurancePlanPopUp: false
        }
      });
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  const handleConfirmPlan = async () => {
    setIsLoading(true);
    try {
      const payload = {
        insurancePlanId: _id
      };

      if (isSignup) {
        await setInsurancePlan(payload);
      } else {
        await updateInsurancePlan(payload);
      }

      setIsSubscribed(true);

      const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
      const user = userInfo.user || {};

      userInfo.user = {
        ...user,
        plannedInsurancePlanId: _id //signup flag
      };

      setBusinessInfo({
        ...businessInfo,

        insurancePlan: {
          ...businessInfo?.insurancePlan,
          rank
        }
      });

      localStorage.setItem('userInfo', JSON.stringify(userInfo));

      if (isHomepage) {
        handleIsInsurancePopUpViewed();
      }
    } catch (error) {
      notify({ msg: error.message, error });
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    if (isSubscribed) {
      if (isHomepage) {
        handlePopupClose();
      } else if (isSettings) {
        getPlans();
      }
    }

    close();
  };

  return (
    <Modal
      className="br-insurance-modal-container"
      title={null}
      footer={null}
      width={null}
      centered={true}
      maskClosable={false}
      wrapClassName={'br-insurance-modal'}
      {...props}
    >
      <LoadingWrapper loading={isLoading}>
        <div className="br-insurance-modal__content">
          {isSubscribed ? (
            <InsurancePlanConfirmation
              isDefault={isDefault}
              rank={rank}
              handleClose={handleClose}
              handleSignupSuccess={handleSignupSuccess}
              handlePopupClose={handlePopupClose}
            />
          ) : (
            <InsurancePlanDetails
              plan={plan}
              handleConfirmPlan={handleConfirmPlan}
              handleClose={handleClose}
              planDetails={planDetails}
            />
          )}
        </div>
      </LoadingWrapper>
    </Modal>
  );
};

export default withRouter(injectIntl(InsurancePlanModal));
