import { bostaHttp } from 'http.js';

export const getInsurancePlans = () => {
  return bostaHttp.get(`pricing/insurancePlan`);
};

export const updateInsurancePlan = (payload) => {
  return bostaHttp.put(`/businesses/insurance-plan`, payload);
};

export const getInsuranceFees = (payload) => {
  return bostaHttp.get(`pricing/insuranceFeeEstimate?`, payload);
};
