import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Divider } from 'antd';

import {
  DAMAGE_REPORTING_BULLETS,
  PACKING_GUIDELINES_BULLETS
} from 'constants/insurancePlans';
import { PACKING_GUIDE } from 'constants/flyers';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as Close } from 'assets/bosta-icons/Close.svg';

import './InsuranceTermsAndCond.less';

const InsuranceTermsAndCond = ({
  intl,
  close,
  isChosenPlan = false,
  handlePlanSelect,
  ...props
}) => {
  const [isTermsRead, setIsTermsRead] = useState(false);

  const contentRef = useRef(null);

  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;

      if (scrollHeight === clientHeight) {
        setIsTermsRead(true);
      }
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsTermsRead(true);
      }
    }
  };
  useEffect(() => {
    const currentRef = contentRef.current;

    if (currentRef) {
      const { scrollHeight, clientHeight } = currentRef;
      if (scrollHeight <= clientHeight) {
        setIsTermsRead(true);
      } else {
        currentRef.addEventListener('scroll', handleScroll);
      }
    }

    return () => {
      currentRef?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleAcceptTerms = () => {
    close();
    handlePlanSelect();
  };

  const renderDamageReporting = () => {
    return (
      <div className="br-insurance-terms-modal__section">
        <span className="br-insurance-terms-modal__section-title">
          {intl.formatMessage({
            id: 'insurance.terms_and_co.damage_reporting.title'
          })}
        </span>

        <ul className="br-insurance-terms-modal__section-bullets">
          {DAMAGE_REPORTING_BULLETS.map((item, index) => (
            <li key={index}>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderPackingGuidelines = () => {
    return (
      <div className="br-insurance-terms-modal__section">
        <span className="br-insurance-terms-modal__section-title">
          {intl.formatMessage(
            {
              id: `insurance.terms_and_co.packing_guidelines.title`
            },
            {
              link: (
                <a
                  href={PACKING_GUIDE}
                  target="blank"
                  className="br-insurance-terms-modal__link"
                >
                  {intl.formatMessage({ id: `insurance.terms_and_co.here` })}
                </a>
              )
            }
          )}
        </span>
        <ul className="br-insurance-terms-modal__section-bullets">
          {PACKING_GUIDELINES_BULLETS.map((item, index) => (
            <li key={index}>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <Modal
      className="br-insurance-terms-modal-container"
      title={null}
      footer={
        isChosenPlan ? (
          <BRButton
            type="primary"
            disabled={!isTermsRead}
            onClick={handleAcceptTerms}
            className="br-insurance-terms-modal__agree"
            label={intl.formatMessage({
              id: 'insurance.terms_and_co.i_agree'
            })}
          />
        ) : null
      }
      width={null}
      centered={true}
      onCancel={close}
      maskClosable={true}
      wrapClassName={'br-insurance-terms-modal'}
      {...props}
    >
      <div className="br-insurance-terms-modal__title">
        <span>
          {intl.formatMessage({
            id: 'insurance.plans.common.terms_and_conditions'
          })}
        </span>
        <Close onClick={close} />
      </div>
      <Divider />
      <div className="br-insurance-terms-modal__content" ref={contentRef}>
        <span className="body">
          {intl.formatMessage({
            id: 'insurance.terms_and_co.disclaimer'
          })}
        </span>
        <Divider />
        {renderDamageReporting()}
        <Divider />
        {renderPackingGuidelines()}
      </div>
    </Modal>
  );
};

export default injectIntl(InsuranceTermsAndCond);
