import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const INSURANCE_PLAN = (rank, isDefault, handleTermsAndCo) => {
  return [
    fmt(
      {
        id: `insurance.plans.rank_${rank}.details.1`
      },
      {
        span: (children) => <span>{children}</span>
      }
    ),
    fmt(
      {
        id: `insurance.plans.rank_${rank}.details.2`
      },
      isDefault
        ? { span: (children) => <span>{children}</span> }
        : {
            terms: (
              <span
                onClick={() => handleTermsAndCo()}
                className="insurance-terms-link"
              >
                {fmt({
                  id: 'insurance.plans.common.terms_and_conditions'
                })}
              </span>
            )
          }
    )
  ];
};

export const DAMAGE_REPORTING_BULLETS = [
  fmt({
    id: `insurance.terms_and_co.damage_reporting.1`
  }),
  fmt({
    id: `insurance.terms_and_co.damage_reporting.2`
  })
];

export const PACKING_GUIDELINES_BULLETS = [
  fmt({
    id: `insurance.terms_and_co.packing_guidelines.1`
  }),
  fmt({
    id: `insurance.terms_and_co.packing_guidelines.2`
  }),
  fmt({
    id: `insurance.terms_and_co.packing_guidelines.3`
  }),
  fmt({
    id: `insurance.terms_and_co.packing_guidelines.4`
  }),
  fmt({
    id: `insurance.terms_and_co.packing_guidelines.5`
  })
];

export const NO_INSURANCE_PLAN_RANK = 10;
