import React, { useEffect, useState } from 'react';
import { mediaHook } from 'customHooks';
import { injectIntl } from 'react-intl';

import { STEPPER } from 'constants/signup';
import { getInsurancePlans } from 'services/insurance';

import InsurancePlanCard from 'components/InsurancePlanCard/InsurancePlanCard';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import './InsurancePlan.less';

const InsurancePlan = ({ intl, handleSignupSuccess, stepperCurrentStep }) => {
  const [insurancePlans, setInsurancePlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getPlans = async () => {
    setIsLoading(true);
    try {
      const { insurancePlans: plans } = await getInsurancePlans();
      setInsurancePlans(plans);
    } catch (error) {
      notify({ msg: error.message, error });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <div className="br-signup-insurance__container">
      <div className="br-signup-insurance__info">
        <span className="br-signup__services-steps subheading">
          {intl.formatMessage(
            {
              id: 'sign_up.services.steps'
            },
            {
              currentStep: stepperCurrentStep,
              lastStep: STEPPER.LAST_STEP
            }
          )}
        </span>
        <span className="br-signup-insurance__info-title">
          {intl.formatMessage({
            id: 'insurance.register.title'
          })}
        </span>
        <span className="br-signup-insurance__info-subtitle">
          {intl.formatMessage({
            id: 'insurance.register.subtitle'
          })}
        </span>
      </div>
      <div className="br-signup-insurance__plans">
        <LoadingWrapper loading={isLoading}>
          {insurancePlans?.map((plan) => (
            <div key={plan.rank}>
              <InsurancePlanCard
                plan={plan}
                handleSignupSuccess={handleSignupSuccess}
              />
            </div>
          ))}
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default injectIntl(mediaHook(InsurancePlan));
