import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Input, Collapse, Tag } from 'antd';
import Highlighter from 'react-highlight-words';
import classNames from 'classnames';

import { getAllAreas } from 'services/cities';
import { CONTEXT } from 'constants/common';
import { LOCALE } from 'constants/intl-wrapper';
import { getCityAreaPlaceholder } from 'constants/countries/countries-mapping';
import {
  convertArrayToNestedObject,
  getAreaName,
  getCityName,
  isArabic,
  normalizeText
} from 'utils/helpers';
import { mediaHook } from 'customHooks';
import { ALL_COUNTRIES } from 'constants/country-data';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRButton from 'components/BRButton/BRButton';
import Suggestions from 'components/BRCityAreaCollapse/components/Suggestions/Suggestions';

import EmptySearchView from 'assets/bosta-icons/Empty-Search-View.svg';
import { ReactComponent as Down } from 'assets/bosta-icons/Down.svg';
import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as SearchIcon } from 'assets/bosta-icons/Search.svg';

import './BRCityAreaCollapse.less';

const { Panel } = Collapse;
const { AR, EN } = LOCALE;

const BRCityAreaCollapse = ({
  intl,
  name,
  formRef,
  availability,
  setCities = () => {},
  businessInfo,
  updateAddress = () => {},
  mobileScreenSizes: { isSmallMobileScreen },
  setSelectedCityArea = () => {},
  selectedCityArea = {},
  setCityArea,
  close,
  fetchedAreas = [],
  setUserPosition = () => {},
  onChange = () => {},
  onResetGoogleMapValues = () => {},
  selectedCountryId,
  countriesList = ALL_COUNTRIES,
  distirctSuggestions = [],
  ...restOfProps
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [isAllAreasLoading, setIsAreasLoading] = useState(false);
  const [allAreas, setAllAreas] = useState(fetchedAreas);
  const [searchResults, setSearchResults] = useState(fetchedAreas);
  const [expandedCityId, setExpandedCityId] = useState(null);
  const [typingLanguage, setTypingLanguage] = useState(intl.locale);
  const [activeKeys, setActiveKeys] = useState([]);

  const antModal = document.querySelector('.br-city-area-modal .ant-modal');
  const antModalContent = document.querySelector(
    '.br-city-area-modal .ant-modal-content'
  );
  const antModalBody = document.querySelector(
    '.br-city-area-modal .ant-modal-body'
  );

  const clearSearch = () => {
    setSearchValue('');
    setSearchResults(allAreas);
    setActiveKeys([]);
    setTypingLanguage(intl.locale);
  };

  const isFound = ({ searchTerm, textToSearchIn }) => {
    return normalizeText(textToSearchIn.toLowerCase()).includes(
      normalizeText(searchTerm.toLowerCase())
    );
  };

  const handleOnSearch = ({ target }) => {
    const value = target.value?.trimStart();
    const currentTypingLanguage = isArabic(value) ? AR : EN;
    if (value) {
      setSearchValue(value);
      setTypingLanguage(currentTypingLanguage);
      const cityActiveKeys = [];
      const results = [];
      allAreas.forEach((city) => {
        const { cityId, cityName, cityOtherName, districts = [] } = city;
        const isFoundInCity = isFound({
          searchTerm: value,
          textToSearchIn: getCityName({
            cityName,
            cityOtherName,
            locale: intl.locale,
            typingLanguage: currentTypingLanguage
          })
        });

        if (isFoundInCity) {
          results.push(city);
          cityActiveKeys.push(cityId);
        } else {
          const areaResults = districts.filter((area) => {
            const isFoundInArea = isFound({
              searchTerm: value,
              textToSearchIn: getAreaName({
                area,
                availability,
                locale: intl.locale,
                typingLanguage: currentTypingLanguage
              })
            });

            return isFoundInArea;
          });

          if (areaResults.length) {
            cityActiveKeys.push(cityId);
            results.push({
              ...city,
              districts: areaResults
            });
          }
        }
      });

      setSearchResults(results);
      setActiveKeys(cityActiveKeys);
    } else {
      clearSearch();
    }
  };

  const fetchData = async () => {
    setIsAreasLoading(true);
    const data =
      (await getAllAreas(
        {
          context: CONTEXT[availability],
          ...(selectedCountryId && { countryId: selectedCountryId })
        },
        businessInfo?.country?.code
      )) || [];

    setAllAreas(data);
    setSearchResults(data);
    setCities(data);
    setIsAreasLoading(false);
  };

  const Highlight = ({ textToHighlight }) => (
    <Highlighter
      highlightClassName="br-city-area__highlighted-text"
      searchWords={[searchValue]}
      autoEscape
      sanitize={normalizeText}
      textToHighlight={textToHighlight}
    />
  );

  const updateFieldValue = (value) => {
    const result = Array.isArray(name)
      ? convertArrayToNestedObject(name, value)
      : { [name]: value };

    formRef?.current?.setFieldsValue(result);
  };

  const handleSelectCityArea = (area) => {
    updateFieldValue(area?.districtId);
    onChange({
      cityName: area.cityName,
      cityOtherName: area.cityOtherName,
      cityID: area?.cityId,
      areaName: area?.districtName,
      areaId: area?.districtId
    });
    setSelectedCityArea(area);
    setCityArea(area);
    updateAddress({
      city: area?.cityName,
      districtId: area?.districtId,
      districtName: area?.districtName
    });
    setUserPosition(null);
    close();
  };

  const renderArea = (area) => {
    const localizedAreaName = getAreaName({
      area,
      availability,
      locale: intl.locale,
      typingLanguage
    });
    const isUncoveredArea = area?.[availability] === false;

    return (
      <div key={area.districtId} id={area.districtId}>
        <div
          className={classNames('br-city-area__area', {
            'br-uncovered-area': isUncoveredArea,
            'br-selected-area': area.districtId === selectedCityArea.districtId
          })}
          onClick={() => !isUncoveredArea && handleSelectCityArea(area)}
        >
          <span className="br-city-area__selected-indicator" />
          <div className="br-city-area__area-container">
            <span className="br-city-area__area-text">
              <Highlight textToHighlight={localizedAreaName} />
            </span>
            {isUncoveredArea && (
              <Tag className="br-city-area__uncovered-tag ant-tag-light-gray">
                {intl.formatMessage({
                  id: 'form.uncovered_zone'
                })}
              </Tag>
            )}
          </div>
        </div>
        <span className="br-city-area__area__divider" />
      </div>
    );
  };

  const handleOnChange = (keys) => {
    setActiveKeys(keys);
  };

  const renderNotFound = () => (
    <div className="br-city-area__no-results-search">
      <img alt="empty-search-view" src={EmptySearchView} />
      <span className="br-city-area__no-results-title">
        {intl.formatMessage({
          id: 'br_area.no_search_title'
        })}
      </span>
      <BRButton
        type="treitary-color"
        className="br-city-area-collapse__cancel-search-btn"
        label={intl.formatMessage({
          id: 'br_area.clear_search'
        })}
        onClick={clearSearch}
      />
    </div>
  );

  const handleClose = () => {
    close();
  };

  const getCountryCode = () => {
    const { codeName } = countriesList.find(
      (country) => country.id === selectedCountryId
    );

    return codeName;
  };

  const renderSearchInput = () => (
    <div className="br-city-area-collapse__search">
      <Input
        placeholder={intl.formatMessage({
          id: getCityAreaPlaceholder(selectedCountryId && getCountryCode())
        })}
        value={searchValue}
        onChange={handleOnSearch}
        ref={(inputRef) => inputRef && inputRef.focus()}
        prefix={<SearchIcon />}
        allowClear
      />
      {searchValue && (
        <BRButton
          type="treitary-color"
          label={intl.formatMessage({
            id: 'common.cancel'
          })}
          className="br-city-area-collapse__cancel-search-btn"
          onClick={clearSearch}
        />
      )}
    </div>
  );

  const onCityClick = (id) => {
    setExpandedCityId(expandedCityId && id === expandedCityId ? null : id);
  };

  useEffect(() => {
    if (!fetchedAreas.length) {
      fetchData();
    }
  }, [availability]);

  useEffect(() => {
    if (isSmallMobileScreen && antModal && antModalContent && antModalBody) {
      antModal.style.height = window.innerHeight;
      antModalContent.style.height = window.innerHeight;
      antModalBody.style.height = `${window.innerHeight - 136}px`;
    }
  }, [isSmallMobileScreen]);

  useEffect(() => {
    if (expandedCityId) {
      const cityDiv = document.getElementById(expandedCityId);
      cityDiv.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [expandedCityId]);

  useEffect(() => {
    if (selectedCityArea?.districtId) {
      const areaDiv = document.getElementById(selectedCityArea.districtId);
      if (areaDiv) {
        areaDiv.scrollIntoView({ block: 'center' });
      }
    }
  }, []);

  return (
    <Modal
      wrapClassName="br-city-area-modal"
      title={
        <>
          <div className="br-city-area-modal__title">
            <span>
              {intl.formatMessage({
                id: 'br_area.select_area'
              })}
            </span>
            <CloseIcon className="close-icon" onClick={handleClose} />
          </div>
          {renderSearchInput()}
          <Suggestions
            distirctSuggestions={distirctSuggestions}
            allAreas={fetchedAreas}
            handleSelectCityArea={handleSelectCityArea}
            formRef={formRef}
          />
        </>
      }
      footer={null}
      width={null}
      onCancel={handleClose}
      {...restOfProps}
    >
      <LoadingWrapper loading={isAllAreasLoading}>
        <div className="br-city-area-collapse">
          <Collapse
            {...(searchValue
              ? {
                  onChange: handleOnChange,
                  activeKey: activeKeys
                }
              : {
                  accordion: true
                })}
            defaultActiveKey={[selectedCityArea.cityId]}
            ghost
          >
            {searchResults.map(
              ({ cityId, cityName, cityOtherName, districts = [] }) => {
                const localizedCityName = getCityName({
                  cityName,
                  cityOtherName,
                  locale: intl.locale,
                  typingLanguage
                });

                return (
                  <Panel
                    key={cityId}
                    id={cityId}
                    header={
                      <span
                        className="br-city-area-collapse__header"
                        onClick={() => onCityClick(cityId)}
                      >
                        <Highlight textToHighlight={localizedCityName} />
                        <Down className="down-arrow" />
                      </span>
                    }
                    showArrow={false}
                  >
                    {districts.map((area) =>
                      renderArea({
                        ...area,
                        cityId,
                        cityName,
                        cityOtherName
                      })
                    )}
                  </Panel>
                );
              }
            )}
            {searchValue && !searchResults.length && renderNotFound()}
          </Collapse>
        </div>
      </LoadingWrapper>
    </Modal>
  );
};

export default injectIntl(mediaHook(BRCityAreaCollapse));
