import React from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as TickIcon } from 'assets/bosta-icons/success-tick-icon.svg';

import './InsurancePlanConfirmation.less';

const InsurancePlanConfirmation = ({
  intl,
  history,
  isDefault,
  rank,
  handleClose,
  handleSignupSuccess,
  ...props
}) => {
  const isSignup = history.location.pathname.includes('signup');

  const title = intl.formatMessage({
    id: `insurance.plans.rank_${rank}.modal_title`
  });

  const handleRedirect = () => {
    handleClose();
    handleSignupSuccess();
  };

  const handleCloseModal = () => {
    isSignup ? handleRedirect() : handleClose();
  };
  return (
    <div className="br-insurance-confirmation__container">
      <div className="br-insurance-confirmation__header">
        <TickIcon />
        <span className="br-insurance-modal__info-title">
          {intl.formatMessage(
            {
              id: `insurance.confirmation_modal.${
                isDefault ? 'default_title' : 'premium_title'
              }`
            },
            {
              title: title
            }
          )}
        </span>

        <span>
          {intl.formatMessage({
            id: `insurance.confirmation_modal.tips`
          })}
        </span>
      </div>
      <BRButton
        className="br-insurance-card__premium-plan-btn"
        type={'primary'}
        label={intl.formatMessage({
          id: `common.close`
        })}
        onClick={handleCloseModal}
      />
    </div>
  );
};

export default withRouter(injectIntl(InsurancePlanConfirmation));
