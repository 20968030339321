import { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import { ContextWrapper } from 'contexts/wrapper.context';
import { LOCALE } from 'constants/intl-wrapper';

import { ReactComponent as SuggestionsIcon } from 'assets/bosta-icons/star-02.svg';
import { ReactComponent as PlusIcon } from 'assets/bosta-icons/Plus.svg';
import { ReactComponent as ArrowCircleRight } from 'assets/bosta-icons/arrow-circle-right.svg';
import { ReactComponent as ArrowCircleLeft } from 'assets/bosta-icons/arrow-circle-left.svg';

import './Suggestions.less';

const Suggestions = ({
  distirctSuggestions,
  allAreas,
  handleSelectCityArea,
  formRef
}) => {
  const [scrollStatus, setScrollStatus] = useState({
    scrollLeft: false,
    scrollRight: false
  });

  const intl = useIntl();

  const suggestionsListRef = useRef();

  const { businessInfo } = useContext(ContextWrapper);

  const firstLine = formRef?.current?.getFieldValue(['address', 'firstLine']);

  const NEXT = intl.locale === LOCALE.EN ? 'right' : 'left';
  const PREVIOUS = intl.locale === LOCALE.EN ? 'left' : 'right';

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (suggestionsListRef.current) {
        handleOnScroll();
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const handleWheel = (e) => {
    if (suggestionsListRef.current) {
      suggestionsListRef.current.scrollBy({
        left: e.deltaY * 1.5,
        behavior: 'smooth'
      });
    }
  };

  const handleOnScroll = () => {
    const container = suggestionsListRef.current;
    if (!container) return;

    const isRTL = intl.locale !== LOCALE.EN;

    let scrollLeft = container.scrollLeft;

    if (isRTL) {
      if (scrollLeft > 0) {
        scrollLeft = -scrollLeft;
      } else {
        scrollLeft = Math.abs(scrollLeft);
      }
    }

    setScrollStatus({
      scrollLeft: scrollLeft > 0,
      scrollRight:
        scrollLeft < container.scrollWidth - container.clientWidth - 1
    });
  };

  const scroll = (direction) => {
    const container = suggestionsListRef.current;
    if (!container) return;

    const scrollAmount = 200;
    container.scrollBy({
      left: direction === 'left' ? -scrollAmount : scrollAmount,
      behavior: 'smooth'
    });
  };

  const getDistrictData = (districtId) => {
    const area = allAreas.find(({ districts }) =>
      districts.some(({ districtId: _id }) => _id === districtId)
    );

    if (!area) return '';

    const district = area.districts.find(
      ({ districtId: _id }) => _id === districtId
    );

    return { ...area, ...district };
  };

  const generateSuggestions = () =>
    distirctSuggestions.map((districtId) => {
      const { districtName, cityName, zoneName, ...rest } =
        getDistrictData(districtId);
      return (
        <div
          className="br-city-area-collapse__suggestions-item body-medium"
          onClick={() =>
            handleSelectCityArea({ districtName, cityName, zoneName, ...rest })
          }
        >
          <PlusIcon />
          {districtName}, {zoneName}, {cityName}
        </div>
      );
    });

  if (
    businessInfo?.featuresConfigurations?.isAllowedDistrictSuggestions &&
    !firstLine &&
    !distirctSuggestions.length
  ) {
    return (
      <div className="br-city-area-collapse__suggestions-container body-medium">
        {intl.formatMessage({ id: 'br_area.add_address_for_suggestions' })}
      </div>
    );
  } else if (!distirctSuggestions.length) {
    return null;
  }

  return (
    <div className="br-city-area-collapse__suggestions-container">
      <div className="br-city-area-collapse__suggestions-header">
        <div className="br-city-area-collapse__suggestions-title body">
          <SuggestionsIcon />
          {intl.formatMessage({ id: 'br_area.suggestions' })}
        </div>
        <div className="br-city-area-collapse__suggestions-arrows">
          <ArrowCircleLeft
            onClick={() => scroll(PREVIOUS)}
            className={classnames({
              'is-disabled': !scrollStatus.scrollLeft
            })}
          />
          <ArrowCircleRight
            onClick={() => scroll(NEXT)}
            className={classnames({
              'is-disabled': !scrollStatus.scrollRight
            })}
          />
        </div>
      </div>
      <div
        className="br-city-area-collapse__suggestions-list"
        onWheel={handleWheel}
        onScroll={handleOnScroll}
        ref={suggestionsListRef}
      >
        {generateSuggestions()}
      </div>
    </div>
  );
};

export default Suggestions;
