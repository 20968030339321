import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';
import { sendSegment } from 'utils/segment';
import { getDeviceType } from 'utils';

import { fmt } from 'components/IntlWrapper/IntlWrapper';
import SmartStickerGuideModal from 'components/SmartStickerGuideModal/SmartStickerGuideModal';

const locale = getLocaleFromLocalStorage();

export const PACKING_GUIDE = `https://bosta.freshdesk.com/${
  locale || 'en'
}/support/solutions/articles/36000414624-how-to-pack-your-orders`;

export const PAID_QUANTITY_PRICE = 'paidQuantityPrice';
export const PAID_PRICE = '1.00';

export const FLYERS_TITLES = [
  'Small flyers',
  'Medium flyers',
  'Large flyers',
  'X-Large flyers',
  'White bags flyers'
];

export const FREE_FLYERS_ORDER_MIN = 10;
export const DEFAULT_ORDER_MIN = 1;

export const RECENTLY_ADDED_ITEMS = ['Smart Sticker'];
export const SMART_MATERIALS = 'smart';

export const ITEMS_TOOLTIP_DESCRIPTION = ({ openModal }) => {
  return {
    'Smart Sticker': {
      title: fmt({
        id: 'bosta_shop.items_section.tooltip_description.smart_sticker.title'
      }),
      description: fmt({
        id: 'bosta_shop.items_section.tooltip_description.smart_sticker.description'
      }),
      actionLabel: fmt({
        id: 'bosta_shop.items_section.tooltip_description.smart_sticker.label'
      }),
      action: () => {
        sendSegment('Stickers_Opened_Guide', {
          'Entry Point': 'Web_Smart Sticker Shop Item - Tooltip',
          'Device Type': getDeviceType()
        });
        openModal(SmartStickerGuideModal);
      }
    }
  };
};

export const SMART_STICKERS_BANNER_SOURCES = {
  HOME_PAGE: 'HOMEPAGE',
  FLYERS_PAGE: 'FLYERS_PAGE'
};

export const SMART_STICKERS_BANNER_URL =
  'https://storage.googleapis.com/bosta-fe/SmartSticker.gif';
