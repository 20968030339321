import React, { createContext, useState, useEffect } from 'react';

import { updateIsNewFeaturesShown } from 'services/settings';
import {
  getBusiness,
  getUserFullData,
  updateFeatureConfiguration
} from 'services/auth';
import { isRegisteredViaBetaFunnel, isUserAuth } from 'utils/helpers';
import { checkIfPickupFeesIsApplicable, setPickupFlag } from 'utils/pickups';
import { isUserFullyActivated } from 'utils/features-access';
import { DatesProvider } from 'contexts/dates.context';
import { DEFAULT_PICKUP_CUTOFF_TIME } from 'constants/pickups';

import { notify } from 'components/Notify/Notify';
import CreateEditPickupModal from 'components/Pickups/components/CreateEditPickupModal/CreateEditPickupModal';
import PrepareOrdersModal from 'components/Orders/components/PrepareOrdersModal/PrepareOrdersModal';
import PickupFeesModal from 'components/Pickups/components/PickupFeesModal/PickupFeesModal';

export const ContextWrapper = createContext({ name: '' });

export const WrapperProvider = ({ children }) => {
  const [newFeatures, setNewFeatures] = useState({});
  const [businessInfo, setBusinessInfo] = useState({});
  const [draftOrdersCount, setDraftOrdersCount] = useState(0);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [userFullData, setUserFullData] = useState({});
  const [toursFlags, setToursFlags] = useState({
    showOrdersPageTourStep: false,
    showStartSellingTourStep: false
  });
  const [pickupCutoffTime, setPickupCutoffTime] = useState(
    DEFAULT_PICKUP_CUTOFF_TIME
  );

  const [serverTimeData, setServerTimeData] = useState({
    timeZone: {}
  });

  const checkIfUserHasNewFeatures = (data) => {
    const featuresObj = data;
    for (const key in featuresObj) {
      if (data[key] === true && key !== 'bostaBox') {
        delete featuresObj[key];
      }
    }
    return featuresObj;
  };

  const handleUpdateFeaturesConfigurations = async (key, value = true) => {
    try {
      await updateFeatureConfiguration({ [key]: value });
      setBusinessInfo((prev) => ({
        ...prev,
        featuresConfigurations: {
          ...prev?.featuresConfigurations,
          [key]: value
        }
      }));
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  const fetchUserFullData = async () => {
    try {
      const userData = await getUserFullData();
      setUserFullData(userData);
    } catch (error) {
      notify({ msg: error.message, error });
    }
    setIsFetchingData(false);
  };

  const updateLocalStorageWithUserBusinessData = (businessData) => {
    if (!businessData) {
      return;
    }
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const {
      businessLegalInfo,
      bankInfo,
      defaultPickupLocation,
      insurancePlan
    } = businessData;

    if (bankInfo) {
      userInfo.user.businessAdminInfo = {
        ...userInfo.user.businessAdminInfo,
        bankInfo
      };
    }
    if (defaultPickupLocation) {
      userInfo.user.businessAdminInfo = {
        ...userInfo.user.businessAdminInfo,
        defaultPickupLocation
      };
    }
    // if (businessLegalInfo?.haveCommercialId !== undefined) {
    //   if (businessLegalInfo?.haveCommercialId) {
    //     userInfo.user.businessAdminInfo = {
    //       ...userInfo.user.businessAdminInfo,
    //       businessLegalInfo: {
    //         ...businessLegalInfo,
    //         haveCommercialId: true,
    //         businessNationalId: false,
    //         businessTaxId: businessLegalInfo.businessTaxId ? true : false,
    //         legalBusinessName: businessLegalInfo.legalBusinessName
    //       }
    //     };
    //   } else {
    //     userInfo.user.businessAdminInfo = {
    //       ...userInfo.user.businessAdminInfo,
    //       businessLegalInfo: {
    //         ...businessLegalInfo,
    //         haveCommercialId: false,
    //         businessNationalId: businessLegalInfo.businessNationalId
    //           ? true
    //           : false,
    //         businessTaxId: false
    //       }
    //     };
    //   }
    // } else if (businessLegalInfo?.businessNationalId) {
    //   userInfo.user.businessAdminInfo = {
    //     ...userInfo.user.businessAdminInfo,
    //     businessLegalInfo: {
    //       ...businessLegalInfo,
    //       businessNationalId: true
    //     }
    //   };
    // }
    if (businessLegalInfo) {
      userInfo.user.businessAdminInfo = {
        ...userInfo.user.businessAdminInfo,
        businessLegalInfo
      };
    }

    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  };

  const getUserInfo = async () => {
    if (!isUserAuth() && JSON.parse(localStorage.getItem('userInfo'))?.token) {
      fetchUserFullData();
      return;
    } else if (!isUserAuth()) {
      setIsFetchingData(false);
      return;
    }
    try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      const businessData = await getBusiness(
        userInfo.user.businessAdminInfo.businessId
      );
      const featuresObj = checkIfUserHasNewFeatures(
        businessData?.newfeatureView
      );
      setNewFeatures(featuresObj);
      setBusinessInfo(businessData);
      setIsFetchingData(false);
      return businessData;
    } catch (error) {
      notify({ msg: error.message, error });
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    getUserInfo().then((businessData) =>
      updateLocalStorageWithUserBusinessData(businessData)
    );
    if (isUserAuth() && (isUserFullyActivated || isRegisteredViaBetaFunnel())) {
      async function checkPickpFlag() {
        await setPickupFlag();
      }
      checkPickpFlag();
    }
  }, []);

  const updateShownFeature = async () => {
    let path = window.location.pathname.trim().split('/')[1];

    if (path === 'shop') {
      path = 'businessFlyers';
    } else if (path === 'inventory') {
      path = 'fulfillmentInfo';
    } else if (path === 'products') {
      path = 'productsPage';
    }

    const featureNeedsUpdate = Object.keys(newFeatures)?.filter((featureName) =>
      featureName.includes(path)
    );
    if (featureNeedsUpdate.length) {
      try {
        await updateIsNewFeaturesShown({ [featureNeedsUpdate[0]]: false });
        const { [featureNeedsUpdate[0]]: _, ...restFeatures } = newFeatures;
        setNewFeatures(restFeatures);
      } catch (error) {
        //
      }
    }
  };

  const updateBusinessInfo = async () => {
    try {
      const businessData = await getUserInfo();
      updateLocalStorageWithUserBusinessData(businessData);
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  const openCreateEditPickupModal = (data) => {
    const { openModal, isCanceledPickup, cancledPickupId } = data;
    openModal(CreateEditPickupModal, {
      cancledPickupId,
      isCanceledPickup,
      businessInfo,
      setBusinessInfo,
      pickupCutoffTime,
      serverTimeData,
      handleOpenPrepareOrderModal,
      handleUpdateFeaturesConfigurations,
      ...data
    });
  };

  const handleOpenCreateEditPickupModal = (data) => {
    const { openModal } = data;
    if (checkIfPickupFeesIsApplicable(businessInfo) && !data?.pickupId) {
      return openModal(PickupFeesModal, {
        onSuccess: () => openCreateEditPickupModal(data)
      });
    }

    openCreateEditPickupModal(data);
  };

  const handleOpenPrepareOrderModal = (data) => {
    const { openModal } = data;
    openModal(PrepareOrdersModal, {
      businessInfo,
      setBusinessInfo,
      handleOpenCreateEditPickupModal,
      ...data
    });
  };

  return (
    <ContextWrapper.Provider
      value={{
        newFeatures,
        setNewFeatures,
        businessInfo,
        setBusinessInfo,
        updateShownFeature,
        updateBusinessInfo,
        draftOrdersCount,
        setDraftOrdersCount,
        isFetchingData,
        toursFlags,
        setToursFlags,
        handleUpdateFeaturesConfigurations,
        userFullData,
        setUserFullData,
        pickupCutoffTime,
        serverTimeData,
        handleOpenCreateEditPickupModal,
        handleOpenPrepareOrderModal
      }}
    >
      <DatesProvider
        setPickupCutoffTime={setPickupCutoffTime}
        setServerTimeData={setServerTimeData}
        serverTimeData={serverTimeData}
        pickupCutoffTime={pickupCutoffTime}
      >
        {children}
      </DatesProvider>
    </ContextWrapper.Provider>
  );
};
