import { bostaHttp } from 'http.js';
import { downloadHeader } from 'utils/download';

export const fetchCyclesOrders = (payload) => {
  return bostaHttp.get(`/wallet/cycles`, payload);
};

export const fetchCyclesCards = (payload) => {
  return bostaHttp.get(`/wallet/cycles/cards`, payload);
};

export const exportCycles = (payload) => {
  return bostaHttp.post('/wallet/cycles/xlsx', payload, downloadHeader);
};

export const fetchBalanceTransactions = (payload) => {
  return bostaHttp.get(`/wallet/transactions`, payload);
};

export const fetchBalanceCards = () => {
  return bostaHttp.get(`wallet/transactions/cards`);
};

export const exportTransactions = (payload) => {
  return bostaHttp.get('/wallet/transactions/xlsx', payload);
};

export const exportCompensation = (payload) => {
  return bostaHttp.get('/wallet/compensation/xlsx', payload, downloadHeader);
};

export const getBalanceReceipt = ({ transactionId, category }) => {
  return bostaHttp.get(`/wallet/receipt/${transactionId}/${category}`);
};

export const generateOTP = (id) => {
  return bostaHttp.post(`/wallet/generate-otp`);
};

export const confirmOTP = (payload) => {
  return bostaHttp.post(`/wallet/confirm-otp`, payload);
};

export const checkOTP = () => {
  return bostaHttp.get(`/wallet/check-otp`);
};

export const changeCashoutFrequency = (payload) => {
  return bostaHttp.put(`/businesses/payment-frequency`, payload);
};

//////////////////////////  International  //////////////////////
export const fetchInternationalCyclesOrders = (payload) => {
  return bostaHttp.get(`/wallet/international/cycles`, payload);
};

export const fetchInternationalCyclesCard = (payload) => {
  return bostaHttp.get('wallet/international/cycles/cards', payload);
};

export const exportInternationalCycles = (payload) => {
  return bostaHttp.post(`/wallet/international/cycles/export`, payload);
};

export const fetchInternationalBalanceTransactions = (payload) => {
  return bostaHttp.get(`/wallet/international/transactions`, payload);
};

export const fetchInternationalBalanceCards = () => {
  return bostaHttp.get('/wallet/international/transactions/cards');
};

export const exportInternationalTransactions = (payload) => {
  return bostaHttp.post(`/wallet/international/transactions/export`, payload);
};

export const getInternationalBalanceReceipt = ({ transactionId }) => {
  return bostaHttp.get(
    `/wallet/international/transactions/receipt/${transactionId}`
  );
};

export const getCashoutInfo = () => {
  return bostaHttp.get('/businesses/cash-out-info');
};

export const getCashoutConfiguration = () => {
  return bostaHttp.get('/utils/configuration/cash-out-info');
};

export const getCreditsTransactions = (payload) => {
  return bostaHttp.get('/bostaCredits', payload);
};

export const filterCreditsTransactions = (payload) => {
  return bostaHttp.get('/bostaCredits/search', payload);
};

export const exportCreditsTable = (payload) => {
  return bostaHttp.get('/bostaCredits/export', payload);
};

export const rechargeBostaCredits = (payload) => {
  return bostaHttp.post('/bostaCredits/recharge', payload);
};
