import { fmt } from 'components/IntlWrapper/IntlWrapper';
import {
  getCurrentUserCountryData,
  isReturnToOriginAvailable
} from './countries/countries-mapping';

export const INTERNATIONAL_ORDERS_COLUMNS = [
  {
    title: fmt({
      id: `orders.table_columns.order_id`
    }),
    dataIndex: 'order_id',
    fixed: 'left',
    width: 100
  },
  {
    title: fmt({
      id: `orders.table_columns.customer_info`
    }),
    dataIndex: 'customerInfo',
    width: 130
  },
  {
    title: fmt({
      id: `orders.table_columns.location`
    }),
    dataIndex: 'location',
    width: 130
  },
  {
    title: fmt({
      id: `orders.table_columns.amount`
    }),
    dataIndex: 'cod',
    width: 135
  },
  {
    title: fmt({
      id: 'new_order.invoice_value.custom_vat.title'
    }),
    dataIndex: 'customVAT',
    width: 130
  },
  {
    title: fmt({
      id: `orders.table_columns.status`
    }),
    dataIndex: 'status',
    width: 143
  },
  {
    title: fmt({
      id: `orders.table_columns.date`
    }),
    dataIndex: 'date',
    width: 120
  },
  {
    title: '',
    dataIndex: 'action',
    render: (action) => (
      <div
        className="br-table-actions-btn"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {action}
      </div>
    ),
    width: 50
  }
];

export const INTERNATIONAL_ORDER_LOCATION = {
  SOURCE: 'source',
  DESTINATION: 'destination'
};

export const QUICK_FILTERS = [
  { label: fmt({ id: 'orders_listing.quick_filters.all' }), value: [] },
  {
    label: fmt({ id: 'international_orders.listing.quick_filters.new' }),
    value: ['10']
  },
  {
    label: fmt(
      {
        id: 'international_orders.listing.quick_filters.in_progress'
      },
      {
        countryCodeName: fmt({
          id: `international_orders.countries_code_name.${getCurrentUserCountryData().codeName.toLowerCase()}`
        })
      }
    ),
    value: ['21', '24', '26'],
    extraProps: {
      location: INTERNATIONAL_ORDER_LOCATION.SOURCE
    }
  },
  {
    label: fmt({
      id: 'international_orders.listing.quick_filters.air_transit'
    }),
    value: ['30'],
    extraProps: {
      location: INTERNATIONAL_ORDER_LOCATION.SOURCE
    }
  },
  {
    label: fmt({ id: 'international_orders.listing.quick_filters.at_customs' }),
    value: ['45']
  },
  {
    label: fmt({
      id: 'international_orders.listing.quick_filters.in_progress_destination'
    }),
    value: ['21', '24', '30'],
    extraProps: {
      location: INTERNATIONAL_ORDER_LOCATION.DESTINATION
    }
  },
  {
    label: fmt({
      id: 'international_orders.listing.quick_filters.heading_to_customer'
    }),
    value: ['41']
  },
  {
    label: fmt({ id: 'international_orders.listing.quick_filters.successful' }),
    value: ['60']
  },
  {
    label: fmt({
      id: 'international_orders.listing.quick_filters.unsuccessful'
    }),
    value: ['48']
  }
];

export const INTERNATIONAL_ORDER_STATUS_CODES = {
  CREATED: 10,
  PENDING_PICKUP: 10.1,
  IN_PROGRESS: 21,
  RECEIVED_AT_WAREHOUSE: 24,
  RECEIVED_AT_WAREHOUSE_DESTINATION: 24.1,
  TRANSFERRED: 26,
  IN_TRANSIT: 30,
  IN_TRANSIT_DESTINATION: 30.1,
  OUT_FOR_DELIVERY: 41,
  COMPLETED: 45,
  RETURNED: 46,
  EXCEPTION: 47,
  CANCELED: 48,
  DELIVERED: 60,
  WAITING_FOR_BUSINESS_ACTION: 200
};

export const INTERNATIONAL_ORDER_STATUS = {
  [INTERNATIONAL_ORDER_STATUS_CODES.CREATED]: fmt({
    id: 'orders.order_status.created'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.PENDING_PICKUP]: fmt({
    id: `orders.order_new_statues.pending_pickup`
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.IN_PROGRESS]: fmt({
    id: 'orders_listing.filters.picked_up'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.RECEIVED_AT_WAREHOUSE]: fmt({
    id: 'international_orders.status.in_progress_origin'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.RECEIVED_AT_WAREHOUSE_DESTINATION]: fmt({
    id: 'international_orders.status.in_progress_destination'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.TRANSFERRED]: fmt({
    id: 'international_orders.status.in_progress_origin'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.IN_TRANSIT]: fmt({
    id: 'international_orders.status.air_transit'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.IN_TRANSIT_DESTINATION]: fmt({
    id: 'international_orders.status.in_progress_destination'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.COMPLETED]: fmt({
    id: 'international_orders.status.custom_clearance'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.OUT_FOR_DELIVERY]: fmt({
    id: `orders.order_new_statues.heading_to_customer`
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.DELIVERED]: fmt({
    id: `orders.order_new_statues.successful`
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.RETURNED]: fmt({
    id: `orders.order_new_statues.returned`
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.CANCELED]: fmt({
    id: 'orders_listing.filters.canceled'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.EXCEPTION]: fmt({
    id: 'international_orders.status.exception'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.WAITING_FOR_BUSINESS_ACTION]: fmt({
    id: 'orders_listing.filters.awaiting_your_action'
  })
};

export const INTERNATIONAL_ORDER_STATUS_DESCRIPTION = {
  [INTERNATIONAL_ORDER_STATUS_CODES.CREATED]: fmt({
    id: 'international_orders.state_description.created'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.PENDING_PICKUP]: fmt({
    id: 'international_orders.state_description.pending_pickup'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.IN_PROGRESS]: fmt({
    id: 'international_orders.state_description.picked_up'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.RECEIVED_AT_WAREHOUSE]: fmt({
    id: 'international_orders.state_description.received_at_warehouse'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.RECEIVED_AT_WAREHOUSE_DESTINATION]: fmt({
    id: 'international_orders.state_description.received_at_warehouse'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.TRANSFERRED]: fmt({
    id: 'international_orders.state_description.received_at_warehouse'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.IN_TRANSIT]: fmt({
    id: 'international_orders.state_description.air_transit'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.IN_TRANSIT_DESTINATION]: fmt({
    id: 'international_orders.state_description.in_progress_destination'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.COMPLETED]: fmt({
    id: 'international_orders.state_description.custom_clearance'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.OUT_FOR_DELIVERY]: fmt({
    id: 'international_orders.state_description.heading_to_customer'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.DELIVERED]: fmt({
    id: 'international_orders.state_description.successful'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.RETURNED]: fmt({
    id: 'international_orders.state_description.returned'
  }),
  [INTERNATIONAL_ORDER_STATUS_CODES.CANCELED]: fmt({
    id: 'international_orders.state_description.canceled'
  })
};

export const INTERNATIONAL_ORDER_STATUS_COLOR = {
  [INTERNATIONAL_ORDER_STATUS_CODES.CREATED]: 'br-state-teal-group',
  [INTERNATIONAL_ORDER_STATUS_CODES.PENDING_PICKUP]: 'br-state-blue-group',
  [INTERNATIONAL_ORDER_STATUS_CODES.IN_PROGRESS]: 'br-state-blue-group',
  [INTERNATIONAL_ORDER_STATUS_CODES.RECEIVED_AT_WAREHOUSE]:
    'br-state-teal-group',
  [INTERNATIONAL_ORDER_STATUS_CODES.RECEIVED_AT_WAREHOUSE_DESTINATION]:
    'br-state-teal-group',
  [INTERNATIONAL_ORDER_STATUS_CODES.TRANSFERRED]: 'br-state-blue-group',
  [INTERNATIONAL_ORDER_STATUS_CODES.IN_TRANSIT]: 'br-state-yellow-group',
  [INTERNATIONAL_ORDER_STATUS_CODES.IN_TRANSIT_DESTINATION]:
    'br-state-yellow-group',
  [INTERNATIONAL_ORDER_STATUS_CODES.OUT_FOR_DELIVERY]: 'br-state-blue-group',
  [INTERNATIONAL_ORDER_STATUS_CODES.COMPLETED]: 'br-state-green-group',
  [INTERNATIONAL_ORDER_STATUS_CODES.DELIVERED]: 'br-state-green-group',
  [INTERNATIONAL_ORDER_STATUS_CODES.RETURNED]: 'br-state-green-group',
  [INTERNATIONAL_ORDER_STATUS_CODES.CANCELED]: 'br-state-red-group',
  [INTERNATIONAL_ORDER_STATUS_CODES.WAITING_FOR_BUSINESS_ACTION]:
    'br-state-yellow-group',
  [INTERNATIONAL_ORDER_STATUS_CODES.EXCEPTION]: 'br-state-yellow-group'
};

export const INTERNATIONAL_ORDER_SEARCH_TYPES = {
  RECEIVER_PHONE: 'receiverPhone',
  TRACKING_NUMBER: 'trackingNumber',
  BUSINESS_REFERENCE: 'businessReference'
};

export const INTERNATIONAL_ORDERS_SEARCH_BY_LABELS = {
  [INTERNATIONAL_ORDER_SEARCH_TYPES.TRACKING_NUMBER]: fmt({
    id: 'orders_listing.actions.order_id'
  }),
  [INTERNATIONAL_ORDER_SEARCH_TYPES.RECEIVER_PHONE]: fmt({
    id: 'orders_listing.actions.phone_number'
  }),
  [INTERNATIONAL_ORDER_SEARCH_TYPES.BUSINESS_REFERENCE]: fmt({
    id: 'orders_listing.actions.order_ref'
  })
};

export const INTERNATIONAL_ORDERS_SEARCH_PLACEHOLDERS_KEYS = {
  [INTERNATIONAL_ORDER_SEARCH_TYPES.RECEIVER_PHONE]:
    'search_mobile_placeholder',
  [INTERNATIONAL_ORDER_SEARCH_TYPES.TRACKING_NUMBER]:
    'search_tracking_placeholder',
  [INTERNATIONAL_ORDER_SEARCH_TYPES.BUSINESS_REFERENCE]:
    'search_reference_placeholder'
};

export const INTERNATIONAL_ORDERS_SEARCH_BY_OPTIONS = [
  {
    value: INTERNATIONAL_ORDER_SEARCH_TYPES.RECEIVER_PHONE,
    label:
      INTERNATIONAL_ORDERS_SEARCH_BY_LABELS[
        INTERNATIONAL_ORDER_SEARCH_TYPES.RECEIVER_PHONE
      ]
  },
  {
    value: INTERNATIONAL_ORDER_SEARCH_TYPES.TRACKING_NUMBER,
    label:
      INTERNATIONAL_ORDERS_SEARCH_BY_LABELS[
        INTERNATIONAL_ORDER_SEARCH_TYPES.TRACKING_NUMBER
      ]
  },
  {
    value: INTERNATIONAL_ORDER_SEARCH_TYPES.BUSINESS_REFERENCE,
    label:
      INTERNATIONAL_ORDERS_SEARCH_BY_LABELS[
        INTERNATIONAL_ORDER_SEARCH_TYPES.BUSINESS_REFERENCE
      ]
  }
];

export const CUSTOM_VAT_TYPES = {
  DDU: 'DDU',
  DDP: 'DDP'
};

export const CUSTOM_VAT_TYPES_OPTIONS = {
  [CUSTOM_VAT_TYPES.DDU]: {
    value: CUSTOM_VAT_TYPES.DDU,
    label: fmt({ id: 'new_order.invoice_value.custom_vat.ddu.label' }),
    description: fmt({
      id: 'new_order.invoice_value.custom_vat.ddu.description'
    })
  },
  [CUSTOM_VAT_TYPES.DDP]: {
    value: CUSTOM_VAT_TYPES.DDP,
    label: fmt({ id: 'new_order.invoice_value.custom_vat.ddp.label' }),
    description: fmt({
      id: 'new_order.invoice_value.custom_vat.ddp.description'
    })
  }
};

export const RETURN_TYPES = {
  RETURN_ADDRESS: 'returnAddress',
  RETURN_TO_FULFILLMENT: 'returnToFulfillmentHub',
  TERMINATE: 'terminateIfFailed',
  RETURN_TO_ORIGIN: 'returnToDefaultLocation'
};

export const RETURN_LOCATIONS_OPTIONS = [
  // {
  //   value: RETURN_TYPES.RETURN_ADDRESS,
  //   label: fmt({
  //     id: 'new_order.order_details.international_return.return_address.label'
  //   })
  // },
  // {
  //   value: RETURN_TYPES.RETURN_TO_FULFILLMENT,
  //   label: fmt({
  //     id: 'new_order.order_details.international_return.return_to_fulfillment.label'
  //   }),
  //   description: fmt({
  //     id: 'new_order.order_details.international_return.return_to_fulfillment.description'
  //   }),
  //   access: isReturnToFulfillmentAvailable()
  // },
  {
    value: RETURN_TYPES.TERMINATE,
    label: fmt({
      id: 'new_order.order_details.international_return.terminate.label'
    }),
    description: fmt({
      id: 'new_order.order_details.international_return.terminate.description'
    })
  }
  // {
  //   value: RETURN_TYPES.RETURN_TO_ORIGIN,
  //   label: fmt({
  //     id: 'new_order.order_details.international_return.return_to_origin.label'
  //   }),
  //   access: isReturnToOriginAvailable()
  // }
];

export const INTERNATIONAL_ORDER_DIMENSIONS = [
  {
    label: fmt({
      id: 'new_order.order_details.international_weight.length.label'
    }),
    activeLabel: fmt({
      id: 'new_order.order_details.international_weight.length.active_label'
    }),
    name: ['specs', 'dimensions', 'length']
  },
  {
    label: fmt({
      id: 'new_order.order_details.international_weight.width.label'
    }),
    activeLabel: fmt({
      id: 'new_order.order_details.international_weight.width.active_label'
    }),
    name: ['specs', 'dimensions', 'width']
  },
  {
    label: fmt({
      id: 'new_order.order_details.international_weight.height.label'
    }),
    activeLabel: fmt({
      id: 'new_order.order_details.international_weight.height.active_label'
    }),
    name: ['specs', 'dimensions', 'height']
  }
];

export const VOLUMETRIC_WEIGHT_DIVIDER = 5000;

export const INTERNATIONAL_BILL_TYPE = {
  INVOICE_BILL: 'invoiceBill',
  VAT_RECEIPT: 'vatReceipt'
};

export const INVOICE_BILL_TYPES = {
  PREFILLED: 'prefilled',
  EMPTY: 'empty'
};

export const INVOICE_BILL_TYPE_OPTIONS = [
  {
    label: fmt({ id: 'international_orders.printing_options.empty_bill' }),
    value: INVOICE_BILL_TYPES.EMPTY
  },
  {
    label: fmt({ id: 'international_orders.printing_options.prefilled_bill' }),
    value: INVOICE_BILL_TYPES.PREFILLED
  }
];

export const DELIVERY_DIMENSIONS_FIELDS = [
  {
    label: fmt({ id: 'international_orders.dimensions.length' }),
    key: 'length'
  },
  {
    label: fmt({ id: 'international_orders.dimensions.width' }),
    key: 'width'
  },
  {
    label: fmt({ id: 'international_orders.dimensions.height' }),
    key: 'height'
  }
];

export const isInternationalOrderDetailsPath = (pathName) =>
  /^\/orders\/international\/(\w+)$/.test(pathName);

export const INTERNATIONAL_ORDER_EVENTS = {
  INTERNATIONAL_ORDER_CREATION: 'INTERNATIONAL_ORDER_CREATION',
  INTERNATIONAL_PICKUP_SCHEDULED: 'INTERNATIONAL_PICKUP_SCHEDULED',
  INTERNATIONAL_PICKED_UP: 'INTERNATIONAL_PICKED_UP',
  INTERNATIONAL_RECEIVED_AT_WAREHOUSE: 'INTERNATIONAL_RECEIVED_AT_WAREHOUSE',
  INTERNATIONAL_TRANSFER: 'INTERNATIONAL_TRANSFER',
  INTERNATIONAL_IN_TRANSIT_SOURCE: 'INTERNATIONAL_IN_TRANSIT_SOURCE',
  INTERNATIONAL_IN_TRANSIT_DESTINATION: 'INTERNATIONAL_IN_TRANSIT_DESTINATION',
  INTERNATIONAL_CLEARED_FROM_CUSTOMS: 'INTERNATIONAL_CLEARED_FROM_CUSTOMS',
  INTERNATIONAL_EXCEPTION: 'INTERNATIONAL_EXCEPTION'
};

export const INTERNATIONAL_ORDER_EVENTS_MAPPING = {
  [INTERNATIONAL_ORDER_EVENTS.INTERNATIONAL_ORDER_CREATION]: fmt({
    id: 'international_orders.logs.created'
  }),
  [INTERNATIONAL_ORDER_EVENTS.INTERNATIONAL_PICKUP_SCHEDULED]: fmt({
    id: 'international_orders.logs.pickup_scheduled'
  }),
  [INTERNATIONAL_ORDER_EVENTS.INTERNATIONAL_PICKED_UP]: fmt({
    id: 'international_orders.logs.picked_up'
  }),
  [INTERNATIONAL_ORDER_EVENTS.INTERNATIONAL_RECEIVED_AT_WAREHOUSE]: fmt({
    id: 'international_orders.logs.received_at_warehouse'
  }),
  [INTERNATIONAL_ORDER_EVENTS.INTERNATIONAL_TRANSFER]: fmt({
    id: 'international_orders.logs.transfer'
  }),
  [INTERNATIONAL_ORDER_EVENTS.INTERNATIONAL_IN_TRANSIT_SOURCE]: fmt({
    id: 'international_orders.logs.in_transit'
  }),
  [INTERNATIONAL_ORDER_EVENTS.INTERNATIONAL_IN_TRANSIT_DESTINATION]: fmt({
    id: 'international_orders.logs.in_transit_destination'
  }),
  [INTERNATIONAL_ORDER_EVENTS.INTERNATIONAL_CLEARED_FROM_CUSTOMS]: fmt({
    id: 'international_orders.logs.completed'
  })
};
