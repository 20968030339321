import { userInfo } from 'utils/helpers';

export const isUserHasFulfillmentAccess = (contextBusinessInfo = undefined) => {
  const businessInfo = contextBusinessInfo ?? userInfo?.businessAdminInfo;

  return (
    businessInfo.fulfillmentInfo?.isActive || businessInfo.isBostaFulfillment
  );
};

export const showBox = !!JSON.parse(localStorage.getItem('showBox'));

export const showDraftOrders =
  userInfo?.businessAdminInfo?.isMarketplaceEnabled;

export const isFirstOrderCreated = userInfo?.isFirstOrderCreated;

export const isFirstPickupCreated = userInfo?.isFirstPickupCreated;

export const isUserFullyActivated = userInfo?.isUserFullyActivated;

export const isAnalyticsBlocked = userInfo?.isAnalyticsBlocked;
